<template>
  <div class="my-request__item" v-if="item && item.id" @click="onOrderClick()">
    <div class="divider"></div>
    <div>
      <h6 class="mt-2" :class="item.new_messages_count > 0 ? 'bold' : ''">
        {{ item.title }} (№{{ item.id }})
        <strong v-if="item.new_messages_count > 0"
          >( {{ item.new_messages_count }} )</strong
        >
      </h6>

      <div v-for="(device, index) in item.devices" :key="index">
        <p class="mb-0" v-if="device.office">
          Офис: <span class="bold">{{ device.office.office_name }}</span>
        </p>

        <p class="mb-0">
          Название: <span class="bold"> {{ device.title }}</span>
        </p>
        <p class="mb-0 fake-link" @click.stop="goToTechniquePage(device.id)">
          Серийный/стикер:
          <span class="bold ml-2">{{ device.serial_num }}</span>
          <span class="bold" v-if="device.sticker_num">
            / {{ device.sticker_num }}</span
          >
        </p>
      </div>
      <p v-if="item.current_status !== null" class="mb-0">
        Статус:
        <span :class="getClass(item.current_status)">
          {{ item.current_status.title }}
        </span>
      </p>

      <div class="fs-14">
        Создано/изменено:
        <span class="bold ml-1"
          >{{ getCreatedDate }} / {{ getUpdatedDate }}</span
        >
      </div>

      <div class="fs-14">
        Автор:
        <span class="bold ml-1">{{ getAuthor }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestItem",
  props: {
    item: Object
  },
  data() {
    return {
      newItem: undefined,
      messageCount: 0,
      newMessages: 0,
      interval: null
    };
  },
  methods: {
    onOrderClick() {
      this.$router.push({ path: "/my-request/chat/" + this.item.id });
    },
    getClass(status) {
      return status.id === 1 ? "yellow" : "blue";
    },
    goToTechniquePage(id) {
      this.$router.push({ name: "TehniquePage", params: { id: id } });
    }
  },
  computed: {
    getCreatedDate() {
      const option = { day: "numeric", month: "numeric", year: "2-digit" };
      return this.item.created_at
        ? new Date(this.item.created_at).toLocaleDateString("ru-RU", option)
        : "Без даты";
    },
    getUpdatedDate() {
      const option = { day: "numeric", month: "numeric", year: "2-digit" };
      return new Date(this.item.updated_at).toLocaleDateString("ru-RU", option);
    },
    getAuthor() {
      let label = "Не указан";
      if (this.item.author_object && this.item.author_object.email) {
        label =
          this.item.author_object.first_name.length > 2 &&
          this.item.author_object.last_name.length > 2
            ? `${this.item.author_object.first_name} ${this.item.author_object.last_name}`
            : this.item.author_object.email;
      }
      return label;
    }
  }
};
</script>

<style lang="scss" scoped>
.row.my-request:last-child {
  justify-self: self-start;
  background: red !important;
}

.fs-14 {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.fake-link {
  span {
    color: #007bff;
  }
}

.my-request__item {
  display: flex;
  min-height: 140px;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
  h3 {
    font-weight: 700;
    margin-top: 10px;
  }
  p {
    font-size: 14px;
    span {
      font-size: 12px;
      font-weight: 700;
    }
    .yellow {
      color: #fbbd3b;
    }
    .blue {
      color: #2378f9;
    }
  }
  .divider {
    width: 0;
    height: calc(100% - 20px);
    background: var(--main-card-color);
    border: 3px solid #e5f1f5;
    border-radius: 5px;
    margin: 0 20px 0 10px;
  }

  // &:not(:last-child) {
  //   margin-right: 13px;
  // }
}

.my-request__item.manager {
  margin-right: 0;
  font-size: 16px;
  .manager-image {
    width: 90px;
  }
  .my-request__title {
    margin-top: 8px;
    margin-left: 30px;
    width: 80%;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    text-align: start;
  }
}

.my-request__title {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #4b4b67;
}
</style>
