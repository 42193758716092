<template>
  <TepmplateBlock
    mod-class
    content-class="mytechnique-wrap"
    title-page="Задачи"
  >
    <help-popover
      message="Раздел задачи, здесь можно найти все или отфильтровать нужные задачи"
      class="ml-2 help-icon"
      :is-inline-block="false"
      style="right: calc(50% + 68px); top: 15px"
    />
    <template #titleAppend>
      <!--      <span @click="showModal" class="ml-2">Добавить</span>-->
      <IconPlus @clicked="showModal" />
      <help-popover
        message="Кнопка создания новой задачи"
        class="ml-2 help-icon"
        :is-inline-block="false"
        style="right: calc(50% + 28px); top: 10px"
      />
    </template>
    <div class="scroller">
      <div class="w-100 mt-4">
        <b-row>
          <b-col :lg="4">
            <label
              >Статус задачи
              <help-popover
                message="Фильтр по статусам задачи. Изначально показывает все открытые задачи"
              />
            </label>
            <b-select v-model="statusIdForSelect" @change="checkStatusId">
              <option value="all_opened">Все открытые</option>
              <option value="request_is_created">Задача создана</option>
              <option value="in_progress">В обработке</option>
              <option value="in_work">В работе</option>
              <option value="is_agreement">На согласовании</option>
              <option value="is_closed">Закрыт</option>
              <option value="all">Все</option>
            </b-select>
          </b-col>

          <b-col :lg="4">
            <label
              >Серийный номер
              <help-popover
                message="Покажет все задачи по 1 конкретному аппарату."
              />
            </label>
            <b-input
              type="search"
              placeholder="Введите номер"
              v-model="listParams.device_serial_num"
              @keyup.enter="getOrders"
            />
          </b-col>

          <b-col :lg="4">
            <label
              >Стикер номер
              <help-popover
                message="Покажет все задачи по 1 конкретному аппарату. Номер указан на наклейке"
              />
            </label>
            <b-input
              type="search"
              placeholder="Введите номер"
              v-model="listParams.device_sticker_num"
              @keyup.enter="getOrders"
            />
          </b-col>

          <b-col :lg="4" class="mt-2">
            <label
              >Офис <help-popover message="Офис, к которому привязан аппарат"
            /></label>
            <div class="position-relative">
              <b-input
                type="search"
                v-model="officeSearch"
                @focus="showOffices = true"
                @blur="hideOffices"
                placeholder="Введите название офиса"
                @input="checkedSearchOffice"
              />
              <div
                class="position-absolute optgroup offices"
                v-if="showOffices"
              >
                <div
                  v-for="item in sortedOfficeByInput"
                  :key="item.id"
                  class="optgroup_item"
                  @click.prevent="selectOffice(item)"
                >
                  {{ item.office_name }}
                </div>

                <span v-if="!sortedOfficeByInput.length">Не найдено</span>
              </div>
            </div>
          </b-col>

          <b-col :lg="4" class="mt-2">
            <label>
              Дата создания задачи от/до
              <help-popover
                message="Дата создания задачи/Дата последнего изменения"
            /></label>
            <b-row>
              <b-col :lg="6">
                <b-input type="date" v-model="created_at_min_not_format" />
              </b-col>
              <b-col :lg="6">
                <b-input type="date" v-model="created_at_max_not_format" />
              </b-col>
            </b-row>
          </b-col>

          <b-col :lg="4" class="mt-2">
            <label
              >Перейти к задаче
              <help-popover
                message="У каждой задачи есть свой номер. Вбейте этот номер и сразу перейдите к нужной задаче"
              />
            </label>
            <b-input
              placeholder="Номер задачи"
              v-model="orderIdField"
              @keyup.enter="goToOrder"
            />
          </b-col>
        </b-row>
        <div class="position-relative">
          <div class="d-flex align-items-center mt-3">
            <div
              class="btn btn-second add-item__btn"
              @click.prevent="getOrders"
            >
              Найти
            </div>
            <help-popover
              class="help-icon"
              message="Кнопка запустит фильтр по выбранным параметрам"
              style="left: 280px; top: 0"
            />
            <div class="clear ml-3" @click="clearFilter">
              Сбросить
            </div>
            <help-popover
              class="help-icon"
              message="Кнопка сбросит все установленные фильтры"
              style="left: 370px; "
            />
          </div>
        </div>
      </div>
      <hr />
    </div>
    <!-- scroll-container down -->
    <div class="scroll-container">
      <div class="d-flex justify-content-center mb-5 mt-5" v-if="isShowSpinner">
        <b-spinner />
      </div>
      <help-popover
        message="Кнопка создания новой задачи"
        style="position: absolute; right: 25px; bottom: 100px"
      />
      <vuescroll @handle-scroll="handleScroll">
        <help-popover
          class="help-icon"
          message="Здесь находятся все задачи"
          style="position:relative; left: 0; "
        />
        <div ref="scrollContainer" class="my-request">
          <!--          <router-link-->
          <!--            class="my-request__link"-->
          <!--            :to="manager.link"-->
          <!--            v-if="!isShowSpinner"-->
          <!--          >-->
          <!--            <div class="my-request__item manager">-->
          <!--              <div class="pl-4">-->
          <!--                <img :src="manager.imgLink" alt="" class="manager-image" />-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <h6 class="my-request__title">{{ manager.text }}</h6>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </router-link>-->

          <RequestItem
            v-for="(item, index) in orders"
            :key="index"
            :item="item"
          />
        </div>
      </vuescroll>
    </div>
    <PlusButton
      ref="plusButtonComponent"
      modalTitle="Выберете тип задачи"
      message="Создание новой задачи.
      Задачи созданные тут - не имеют привязки к чему-то
      и требуют описание проблемы в чате"
      @add-order="onAddOrder"
      :device="null"
      oldorder="true"
      link="/my-techique/chat"
    />
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import PlusButton from "../../components/PlusButton";
import RequestManager from "../../function/request/RequestManager";
import vuescroll from "vuescroll";
import RequestItem from "../../components/RequestItem.vue";
import { mapGetters, mapState } from "vuex";
import HelpPopover from "../../components/HelpPopover";
import IconPlus from "../../components/Form/IconPlus";

export default {
  name: "MyRequest",
  components: {
    HelpPopover,
    TepmplateBlock,
    PlusButton,
    vuescroll,
    RequestItem,
    IconPlus
  },
  computed: {
    ...mapGetters(["officeList", "tagsList"]),
    ...mapState("devices", ["devicesTypes", "devicesStatus"]),
    sortedOfficeByInput() {
      return this.officeList
        .filter(el => {
          return el.office_name
            .toLowerCase()
            .includes(this.officeSearch.toLowerCase());
        })
        .sort((a, b) => a.office_name.localeCompare(b.office_name));
    }
  },
  data() {
    return {
      isShowSpinner: false,
      orderIdField: undefined,
      interval: 0,
      ordersCount: 0,
      manager: {
        text: "Ваш персональный менеджер",
        model: "",
        link: "/manager/chat",
        imgLink: require("../../resource/img/personal-man.svg")
      },
      showOffices: false,
      created_at_min_not_format: undefined,
      created_at_max_not_format: undefined,
      officeSearch: "",
      statusIdForSelect: "all_opened",
      listParams: {
        limit: 20,
        offset: 0,
        device_id: undefined,
        device_tag_id: undefined,
        device_serial_num: undefined,
        device_sticker_num: undefined,
        created_at_min: undefined,
        created_at_max: undefined,
        updated_at_min: undefined,
        updated_at_max: undefined,
        status_id: undefined,
        status_ids: undefined,
        status_not_ids: undefined,
        office_id: undefined,
        author_ids: undefined
      },
      orders: [],
      active: false,
      items: []
    };
  },
  created() {
    this.checkStatusId();
    this.getOrders();
  },
  methods: {
    showModal() {
      this.$refs.plusButtonComponent.showModal();
    },
    goToOrder() {
      RequestManager()
        .getOrdersChat(this.orderIdField)
        .then(data => {
          if (data.success === false) {
            this.$bvToast.toast("Задачи с таким номером не существует", {
              title: "Ошибка",
              variant: "danger"
            });
          } else {
            this.$router.push({
              path: "/my-request/chat/" + this.orderIdField
            });
          }
        });
    },
    checkedSearchOffice() {
      if (!this.officeSearch) {
        this.listParams.office_id = undefined;
      }
      if (this.listParams.office_id && !this.sortedOfficeByInput.length) {
        this.listParams.office_id = undefined;
      }
      if (
        this.sortedOfficeByInput.length === 1 &&
        this.officeSearch === this.sortedOfficeByInput[0].office_name
      ) {
        this.listParams.office_id = this.sortedOfficeByInput[0].id;
      }
    },
    checkStatusId() {
      if (this.statusIdForSelect === "all_opened") {
        this.listParams.status_id = undefined;
        this.listParams.status_not_ids = 4;
      }
      if (this.statusIdForSelect === "request_is_created") {
        this.listParams.status_id = 2;
        this.listParams.status_not_ids = undefined;
      }
      if (this.statusIdForSelect === "in_progress") {
        this.listParams.status_id = 1;
        this.listParams.status_not_ids = undefined;
      }
      if (this.statusIdForSelect === "in_work") {
        this.listParams.status_id = 3;
        this.listParams.status_not_ids = undefined;
      }
      if (this.statusIdForSelect === "is_agreement") {
        this.listParams.status_id = 5;
        this.listParams.status_not_ids = undefined;
      }
      if (this.statusIdForSelect === "is_closed") {
        this.listParams.status_id = 4;
        this.listParams.status_not_ids = undefined;
      }
      if (this.statusIdForSelect === "all") {
        this.listParams.status_id = undefined;
        this.listParams.status_not_ids = undefined;
      }
    },
    clearFilter() {
      this.officeSearch = "";
      this.created_at_max_not_format = undefined;
      this.created_at_min_not_format = undefined;
      this.listParams.limit = 20;
      this.listParams.offset = 0;
      this.listParams.device_id = undefined;
      this.listParams.device_tag_id = undefined;
      this.listParams.device_serial_num = undefined;
      this.listParams.device_sticker_num = undefined;
      this.listParams.created_at_min = undefined;
      this.listParams.created_at_max = undefined;
      this.listParams.updated_at_min = undefined;
      this.listParams.updated_at_max = undefined;
      this.listParams.status_id = undefined;
      this.listParams.office_id = undefined;
      this.statusIdForSelect = "all_opened";
      this.ordersCount = 0;
      this.orders = [];
      this.checkStatusId();
      this.getOrders();
    },
    uniqBy(arr, predicate) {
      const cb =
        typeof predicate === "function" ? predicate : o => o[predicate];

      return [
        ...arr
          .reduce((map, item) => {
            const key = item === null || item === undefined ? item : cb(item);

            map.has(key) || map.set(key, item);

            return map;
          }, new Map())
          .values()
      ];
    },
    getOrders() {
      this.isShowSpinner = true;
      this.orders = [];
      this.listParams.offset = 0;

      Date.prototype._addHours = function(h) {
        this.setHours(this.getHours() + h);
        return this;
      };

      Date.prototype._addMinutes = function(m) {
        this.setMinutes(this.getMinutes() + m);
        return this;
      };

      let maxDate = this.created_at_max_not_format
        ? new Date(this.created_at_max_not_format)._addHours(23)._addMinutes(59)
        : undefined;

      this.listParams.created_at_min = this.created_at_min_not_format
        ? new Date(this.created_at_min_not_format)
        : undefined;
      this.listParams.created_at_max = maxDate;

      RequestManager()
        .getOrdersListWithParams(this.listParams)
        .then(data => {
          this.ordersCount = data.count;
          this.orders = [...this.orders, ...data.results];
          this.orders = this.uniqBy(this.orders, "id");
        })
        .finally(() => {
          this.isShowSpinner = false;
        });
    },
    onAddOrder(data) {
      const path = "/my-request/chat/" + data.id;
      this.$router.push({ path });
    },
    handleScroll(vertical) {
      if (vertical.process === 1 && this.orders.length < this.ordersCount) {
        this.listParams.offset = this.orders.length;
        RequestManager()
          .getOrdersListWithParams(this.listParams)
          .then(data => {
            this.ordersCount = data.count;
            this.orders = [...this.orders, ...data.results];
            this.orders = this.uniqBy(this.orders, "id");
          });
      }
    },
    getStatus(status) {
      if (status === null) {
        return "в обработке";
      } else {
        return "на обслуживании";
      }
    },
    getClass(status) {
      return status.id === 1 ? "yellow" : "blue";
    },
    onOrderClick(id) {
      this.$router.push({ path: "/my-request/chat/" + id });
    },
    selectOffice(item) {
      this.officeSearch = item.office_name;
      this.listParams.office_id = item.id;
    },
    hideOffices() {
      setTimeout(() => {
        this.showOffices = false;
      }, 150);
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 90%;
  .__vuescroll {
    height: calc(100vh - 375px) !important;
    width: 100% !important;
  }
}
@media (min-width: 320px) {
  .main-block {
    overflow-y: scroll;
    scroll-snap-type: x mandatory;
  }

  .scroller {
    width: 100%;
    margin: 0;
  }
}

@media (min-width: 375px) {
  .scroller {
    width: 100%;
    margin: 0;
  }
}

@media (min-width: 425px) {
  .scroller {
    width: 100%;
    margin: 0;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  .my-request__item.manager {
    width: 100%;
    margin-right: 0;
    font-size: 10px;
    .manager-image {
      width: 80px;
    }
  }
}

.my-request {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 15px;
}
.row.my-request:last-child {
  justify-self: self-start;
  background: red !important;
}

.clear {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.optgroup {
  padding: 5px 10px;
  position: absolute;
  width: 100%;
  max-height: 220px;
  border: 1px solid #ced4da;
  z-index: 9;
  background-color: white;
  overflow: auto;

  &_item {
    cursor: pointer;
    &:hover {
      background-color: #fafafc;
    }
  }
}

.my-request__item.manager {
  display: flex;
  min-height: 160px;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  padding: 0 20px;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  .manager-image {
    width: 90px;
  }
  .my-request__title {
    margin-top: 8px;
    margin-left: 30px;
    width: 80%;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    text-align: start;
  }
}

.my-request__title {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #4b4b67;
}

.help-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.add-item__btn {
  background-color: var(--help-popover-color);
}

.form-control {
  background-color: var(--main-card-color);
  &:focus {
    background-color: var(--main-card-color);
    color: var(--main-text-color);
  }
}

.custom-select {
  background-color: var(--main-card-color);
  color: var(--main-text-color);
  &:focus {
    background-color: var(--main-card-color);
  }
}

.offices {
  background-color: var(--main-card-color);
  .optgroup_item:hover {
    background-color: rgba(184, 218, 255, 0.5);
  }
}

.my-request__item {
  background-color: var(--main-card-color);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: var(--filter-for-date);
  ::-webkit-calendar-picker-indicator:hover {
    filter: var(--filter-for-date);
  }
}
</style>
